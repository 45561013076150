import React, { useEffect, useState } from "react"
import "../assets/style.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import featured_story from "../images/featured_story.webp"
import featured_story_low from "../images/featured_story_low.webp"
import StoryCard from "../components/StoryCard"
import LazyImage from "../components/LazyImage"
import axios from "axios"
import AOS from "aos"
import "aos/dist/aos.css"

const ActivitiesPage = props => {
  const [data, setData] = useState([])
  const [fetched, setFetched] = useState(true)

  useEffect(() => {
    AOS.init({
      duration: 1500,
    })

    axios
      .get("https://swapnanagar.herokuapp.com/activities")
      .then(res => {
        setData(res.data)
        console.log(res.data)
        setFetched(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <div>
      <SEO title="Activities" />
      <Header currentPage="activities" />

      <div className="pt-5"></div>
      <div className="container mt-4" data-aos="fade-up">
        <div className="row justify-content-between align-items-center flex-wrap-reverse pt-5 fade-in">
          <div className="col-lg-6">
            <h1 className="my-3">
              Student's activities <strong>beyond the books</strong>
            </h1>

            {/* <h4 className="text-dark mt-3 mb-2">
              Fantastic performance by Swapnanagar Kids
            </h4>

            <div className="margin-line"></div> */}

            <p className="mt-2">
              We Provide students with full learning support (without the need
              of private tutor or note books) in an optimized school environment
              covering sufficient learning, socialization, playing and cultural
              engagements for all all-round development. To build maximum
              understanding on lessons we practice active learning.
            </p>
            <br />
            {/* <button className="btn btn-primary py-2">Read More</button> */}
          </div>
          <div className="col-lg-5">
            <LazyImage
              lowRes={featured_story_low}
              highRes={featured_story}
              alt="activitiesRight "
              width="100%"
              className="p-2"
            ></LazyImage>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h1>Recent Activities</h1>

        {fetched ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <div className="row mt-4">
          {/* {[1, 2, 3, 4, 5, 6, 7].map(e => (
            <StoryCard key={e} />
          ))} */}
          {data.map(e => (
            <StoryCard
              to={"/details?id=" + e.id}
              key={e.id}
              title={e.title}
              date={e.activity_date}
            ></StoryCard>
          ))}
        </div>
      </div>
      <br />
      <br />
      {/* <div className="container d-flex justify-content-center">
        <button className="btn btn-success ">Show More</button>
      </div> */}
      <div className="mt-5"></div>
      <Footer />
    </div>
  )
}

export default ActivitiesPage
